import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  check: 'check',
  arrowDown: 'arrowDown',
  clear: 'clear',
};

export type IconName = keyof typeof iconList;

export type IconSize = '12' | '15' | '18' | '23' | '24' | '28' | '30' | '32' | '36' | '38' | '80';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
