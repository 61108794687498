import React, { forwardRef } from 'react';
import ReactSelect from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';

import Icon from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

interface PulldownProps {
  name: string;
  options: OptionType[];
  placeholder?: string;
  error?: boolean;
  value?: OptionType;
  multiple?: boolean;
  loading?: boolean;
  isDisabled?: boolean;
  modifier?: 'datepicker';
  onScrollLastest?: () => void;
  handleChange?: (option: OptionType) => void;
}

const PulldownRef: React.ForwardRefRenderFunction<Select, PulldownProps> = (
  {
    name,
    options,
    placeholder,
    error,
    multiple,
    loading,
    isDisabled,
    value,
    onScrollLastest,
    handleChange,
    modifier
  },
  ref,
) => (
  <ReactSelect
    isDisabled={isDisabled}
    ref={ref}
    name={name}
    className={mapModifiers('m-pulldown', error && 'error', modifier)}
    classNamePrefix="m-pulldown"
    options={options}
    placeholder={placeholder}
    isMulti={multiple}
    value={value}
    onChange={(v: any) => handleChange && handleChange(v)}
    theme={(theme) => ({
      ...theme,
      borderRadius: 4,
      colors: {
        ...theme.colors,
      },
      spacing: {
        ...theme.spacing,
        controlHeight: 40,
      },
    })}
    isLoading={loading}
    styles={{
      control: (base: any) => ({
        ...base,
        borderColor: error ? '#E0001A' : '#011e41',
        '&:hover': {
          borderColor: '#011e41',
        },
      }),
    }}
    components={{
      // eslint-disable-next-line react/no-unstable-nested-components
      DropdownIndicator: () => (<Icon iconName="arrowDown" size="24" />)
    }}
    onMenuScrollToBottom={onScrollLastest}
  />
);

const Pulldown = forwardRef(PulldownRef);

export default Pulldown;
