import { ProvinceType } from '.';

import axiosInstance from 'services/common/instance';

const getAllProvinces = async (): Promise<ProvinceType[]> => {
  const res = await axiosInstance.get('locations/provinces/vietnamese');
  return res.data.data;
};

export default getAllProvinces;
