import * as yup from 'yup';

import { UserInfoType } from 'pages/UserInfo';

export const phoneRegExp = /^0[1-9]\d{8}$/;

const useSchemas = () => {
  const userInfoSchema: yup.ObjectSchema<UserInfoType> = yup.object().shape({
    invitation_code: yup.string().when(['isRequireCme'], {
      is: (isRequireCme: boolean) => isRequireCme,
      then: (schema) => schema.required('Vui lòng cung cấp thông tin'),
    }),
    full_name: yup.string().required('Vui lòng cung cấp thông tin'),
    dob: yup.string().nullable(),
    phone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
    position_or_degree: yup.string(),
    hospital: yup.string().required('Vui lòng cung cấp thông tin'),
    department: yup.string().required('Vui lòng cung cấp thông tin'),
    provinceCode: yup.mixed<OptionType>().required('Vui lòng cung cấp thông tin'),
    isRequireCme: yup.boolean(),
  });

  return {
    userInfoSchema,
  };
};

export default useSchemas;
