import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import Input from 'components/atoms/Input';
import Pulldown from 'components/molecules/Pulldown';
import mapModifiers, {
  convertLocalToUTCDate,
  getMonth, months, years,
} from 'utils/functions';

type DatePickerModifiers = 'verify' | 'infoModal' | 'borderRadiusColor' | 'editable';
interface DatepickerProps {
  startDate?: Date;
  handleChangeDate: (date: Date) => void;
  id: string;
  error?: string;
  disabled?: boolean;
  modifiers?: DatePickerModifiers | DatePickerModifiers[];
  innerRef?: React.Ref<HTMLDivElement>;
  theme?: 'light' | 'dark';
  size?: 'md' | 'sm';
  placeholder?: string;
}

const Datepicker: React.FC<DatepickerProps> = ({
  startDate,
  handleChangeDate,
  id,
  error,
  disabled,
  modifiers,
  innerRef,
  theme,
  size,
  placeholder,
}) => (
  <div
    className={mapModifiers(
      'm-datePicker',
      modifiers,
      error && 'error',
      disabled && 'disabled',
      theme,
      size,
    )}
  >
    <DatePicker
      id={id}
      className="m-datePicker_wrapper"
      wrapperClassName="m-datePicker_container"
      popperClassName="m-datePicker_popper"
      calendarClassName="m-datePicker_calendar"
      showPopperArrow={false}
      placeholderText={placeholder}
      selected={startDate}
      onChange={(date: Date) => handleChangeDate(convertLocalToUTCDate(date))}
      dateFormat="dd/MM/yyyy"
      customInput={(
        <Input placeholder={placeholder} />
      )}
      renderCustomHeader={({ date, changeYear, changeMonth }) => (
        <div className="m-datePicker_header" ref={innerRef}>
          <Pulldown
            name="year"
            placeholder={String(date.getFullYear())}
            value={{ label: String(date.getFullYear()), value: String(date.getFullYear()) }}
            options={years}
            modifier="datepicker"
            handleChange={(data) => {
              changeYear(Number(data.label));
            }}
          />
          <Pulldown
            modifier="datepicker"
            name="month"
            placeholder={String(months[getMonth(date)].label)}
            options={months}
            value={{
              label: String(months[getMonth(date)].label),
              value: String(months[getMonth(date)].label),
            }}
            handleChange={(data) => {
              changeMonth(months.findIndex((item) => item.label === data.label));
            }}
          />
        </div>
      )}
    />
  </div>
);

Datepicker.defaultProps = {
  theme: 'light',
  size: 'md',
};

export default Datepicker;
