import React from 'react';
import { Typography } from 'tfc-components';

import useWindowDimensions from 'hooks/useWindowDemensions';

interface MainLayoutProps {
  children?: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { height } = useWindowDimensions();
  return (
    <main className="t-mainLayout" style={{ height: `${height}px`, maxHeight: `${height}px` }}>
      <div className="t-mainLayout_top">
        <Typography.Heading
          textStyle="center"
          fontweight="600"
          extendClasses="t-mainLayout_heading fs-48x68"
        >
          HỘI THẢO KHOA HỌC
        </Typography.Heading>
      </div>
      <div className="t-mainLayout_content">{children}</div>
    </main>
  );
};

export default MainLayout;
