import React from 'react';
import {
  Controller, FormProvider, UseFormReturn
} from 'react-hook-form';
import { Image, Typography } from 'tfc-components';

import kiemtra from 'assets/images/kiemtra.png';
import signup from 'assets/images/signup.png';
import Input from 'components/atoms/Input';

export type CheckInvitationType = {
  invitation_code?: string,
  phone?: string
};

type Props = {
  method: UseFormReturn<CheckInvitationType>;
  handleCheck: (data: CheckInvitationType) => void;
  handleRegister?: () => void;
  errorText?: string
  errorTextType?: string
};

const Register: React.FC<Props> = ({
  method,
  handleCheck,
  handleRegister,
  errorText,
  errorTextType
}) => (
  <>
    <div className="p-register_form">
      <FormProvider {...method}>
        <Controller
          name="invitation_code"
          control={method.control}
          render={({ field, fieldState: { error } }) => (
            <div className="p-register_field">
              <Typography.Text
                fontweight="600"
                extendClasses="fs-26x36 color-antiFlashWhite p-register_field_fieldText"
              >
                MÃ CME
              </Typography.Text>
              <div className="p-register_field_input">
                <Input
                  {...field}
                />
                {
                  (error || errorText) && (
                    <div className="p-register_error">
                      <Typography.Text
                        extendClasses="fs-18x26 p-register_error-text"
                        fontweight="600"
                      >
                        {error?.message || errorText}
                      </Typography.Text>
                    </div>
                  )
                }
              </div>
            </div>
          )}
        />
        <Controller
          name="phone"
          control={method.control}
          render={({ field, fieldState: { error } }) => (
            <div className="p-register_field">
              <Typography.Text
                fontweight="600"
                extendClasses="fs-26x36 color-antiFlashWhite p-register_field_fieldText"
              >
                SỐ ĐIỆN THOẠI
              </Typography.Text>
              <div className="p-register_field_input">
                <Input
                  {...field}
                />
                {
                  error && (
                    <div className="p-register_error">
                      <Typography.Text
                        extendClasses="fs-18x26 p-register_error-text"
                        fontweight="600"
                      >
                        {error.message}
                      </Typography.Text>
                    </div>
                  )
                }
              </div>
            </div>
          )}
        />
      </FormProvider>
    </div>
    <div className="p-register_buttonGroup">
      <div className="p-register_error">
        <Typography.Text
          extendClasses="fs-18x26 p-register_error-text"
          textStyle="center"
          fontweight="600"
        >
          {errorTextType || ''}
        </Typography.Text>
      </div>
      <div
        className="p-register_checkButton"
        onClick={() => handleCheck({
          invitation_code: method.getValues('invitation_code'),
          phone: method.getValues('phone')
        })}
      >
        <Image
          imgSrc={kiemtra}
          alt="checkBtn"
          ratio={600 / 109}
        />
      </div>
      <div
        className="p-register_signupButton"
        onClick={handleRegister}
      >
        <Image
          imgSrc={signup}
          alt="registerBtn"
          ratio={600 / 109}
        />
      </div>
    </div>
  </>
);
export default Register;
