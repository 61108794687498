import React from 'react';
import { Image, Typography } from 'tfc-components';

import hoanthanh from 'assets/images/hoanthanh.png';

type CongratulationProps = {
  handleComplete?: () => void;
};

const Congratulation: React.FC<CongratulationProps> = ({ handleComplete }) => (
  <>
    <div className="p-congratulation">
      <Typography.Text
        textStyle="center"
        fontweight="700"
        extendClasses="p-congratulation_congraText fs-64x84"
      >
        CHÚC MỪNG
        <br />
        QUÝ CHUYÊN VIÊN Y TẾ
        <br />
        ĐĂNG KÝ THAM DỰ
        <br />
        CHƯƠNG TRÌNH
        <br />
        THÀNH CÔNG
      </Typography.Text>
    </div>
    <div
      onClick={handleComplete}
      className="p-congratulation_completeButton"
    >
      <Image
        imgSrc={hoanthanh}
        alt="completeBtn"
        ratio={600 / 109}
      />
    </div>
  </>
);

export default Congratulation;
