import {
  CheckInvitationData, CheckInvitationParams, CheckinParams, GeneralData
} from './types';

import axiosInstance from 'services/common/instance';

export const getGenerakService = async (): Promise<GeneralData> => {
  const response = await axiosInstance.get('systems/general');
  return response.data.data;
};

export const checkInvitationService = async (
  data: CheckInvitationParams
): Promise<CheckInvitationData> => {
  const response = await axiosInstance.post('members/check-invitation-checkin', data);
  return response.data.data;
};

export const checkinService = async (data: CheckinParams) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'object') {
      formData.append(key, value);
    } else if (typeof value === 'boolean') {
      if (value) {
        formData.append(key, '1');
      } else {
        formData.append(key, '0');
      }
    } else if (value) {
      formData.append(key, value.toString());
    }
  });

  const response = await axiosInstance.post('members/check-in', formData);
  return response.data.data;
};
